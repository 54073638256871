<template>
  <v-container fluid>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      small
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
      class="toTopButton"
    >
      <v-icon small>fas fa-chevron-up</v-icon>
    </v-btn>
    <vue-position-sticky
      :offsetTop="chipHeight"
      v-show="showChips"
      sticky-class="stickyChipGroup"
    >
      <v-chip-group
        :show-arrows="false"
        class="white py-1"
        active-class="primary--text"
      >
        <v-chip @click="handleMenuFilter((filter = ''))">
          Todos
        </v-chip>
        <div v-for="(item, idx) in sortedMenu" :key="item.id + idx">
          <v-chip
            @click="handleMenuFilter((filter = item.name))"
            v-if="scopeProductMenu(item.id).length != 0"
          >
            {{ item.name }}
          </v-chip>
        </div>
      </v-chip-group>
    </vue-position-sticky>

    <div v-for="(item, idx) in filteredMenu" :key="item.id + idx">
      <h3
        v-if="scopeProductMenu(item.id).length != 0"
        class="my-4 orange lighten-5 py-1 pl-2 rounded-lg"
        :class="menuTitleClass"
      >
        {{ item.name }}
      </h3>
      <v-row v-if="scopeProductMenu(item.id).length != 0">
        <v-col
          v-for="(product, idx) in scopeProductMenu(item.id).slice(0, limit)"
          :key="product.id + idx"
          cols="12"
          md="6"
          class="d-flex flex-column justify-space-between"
        >
          <div>
            <lazy-component>
              <div
                class="d-flex flex-row align-center align-md-start justify-space-between"
              >
                <div
                  class="text-wrapper d-flex flex-column mr-6"
                  @click="showProduct(product)"
                >
                  <p class="font-weight-black">
                    {{ product.name }}
                  </p>
                  <span class="grey--text text--darken-2">
                    {{ product.description }}
                  </span>
                  <span class="orange--text text--darken-4">
                    {{ product.price | toCurrency }}
                  </span>
                </div>
                <img
                  :src="product.images[0] || ochoLogoPlaceholder"
                  alt="producto"
                  style="height:80px;width:80px;object-fit:cover;"
                  class="rounded-xl"
                  data-aos="fade-down"
                />
              </div>
            </lazy-component>
          </div>
          <v-divider class="my-8"></v-divider>
        </v-col>
        <v-col>
          <div
            v-if="scopeProductMenu(item.id).length > limit"
            class="text-center"
          >
            <router-link
              :to="{ name: 'products', params: { menuId: item.id } }"
            >
              <v-btn rounded color="primary">
                Ver más
                <v-icon small class="ml-2">fas fa-arrow-right</v-icon>
              </v-btn>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </div>
    <transition enter-active-class="animated rubberBand" appear>
      <div
        v-if="cartTotal > 0 && $vuetify.breakpoint.mdAndDown"
        class="d-flex mb-n2"
        :class="
          showFullCartIcon
            ? 'shopping-cart-container-large'
            : 'shopping-cart-container'
        "
      >
        <div
          @click="checkOut()"
          class="primary rounded-pill d-flex py-1 px-2"
          :class="showFullCartIcon ? 'fip-large' : 'fip'"
        >
          <v-icon
            :medium="showFullCartIcon"
            :small="!showFullCartIcon"
            color="white"
          >
            fas fa-shopping-cart
          </v-icon>
          <transition
            enter-active-class="animated fadeIn"
            :duration="{ enter: 500, leave: 0 }"
          >
            <div
              v-if="showFullCartIcon"
              class="white--text d-flex flex-column align"
            >
              <span class="my-0" style="font-size: 10px;">
                Total Carrito
              </span>
              <span class="my-0" style="font-size: 14px;">
                {{ cartTotal | toCurrency }}
              </span>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import AOS from "aos";
import { isMobile } from "mobile-device-detect";

export default {
  props: {
    query: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 15,
    },
    menuId: {
      type: String,
      default: "",
    },
    showChips: {
      type: Boolean,
      default: true,
    },
    menuTitleClass: {
      type: String,
      default: "menu-title",
    },
  },
  data() {
    return {
      fab: false,
      ochoLogoPlaceholder:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpeg?alt=media&token=063d2780-9ef0-4bce-bab6-aac516fe6d28",
      menuFilterBy: "",
      chipHeight: 56,
      lastScroll: 0,
      showFullCartIcon: true,
    };
  },
  mounted() {
    AOS.init({ once: true });
    this.checkChipHeight();
    if (document.querySelector(".stickyChipGroup")) {
      document.querySelector(".stickyChipGroup").style.zIndex = 0;
    }
  },
  computed: {
    ...mapState(["productsStore", "menuStore"]),
    sortedMenu: function() {
      if (this.menuId.length > 0) {
        return this.menuStore.filter((menu) => {
          return menu.id == this.menuId;
        });
      } else {
        return this.menuStore
          .slice()
          .sort((a, b) => (a.position > b.position ? 1 : -1));
      }
    },
    filteredMenu() {
      if (this.menuFilterBy == "") return this.sortedMenu;

      return this.sortedMenu.filter((menu) => {
        return menu.name == this.menuFilterBy;
      });
    },
    cartTotal() {
      return this.$store.getters.cartTotal;
    },
  },
  methods: {
    scopeProductMenu(menuId) {
      let localQuery = (this.query ? this.query : "").toLowerCase();

      return this.productsStore.filter((product) => {
        return (
          product.menu[0] == menuId &&
          product.name.toLowerCase().includes(localQuery)
        );
      });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;

      let currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0 && this.lastScroll <= currentScroll) {
        this.lastScroll = currentScroll;
        this.showFullCartIcon = false;
      } else {
        this.lastScroll = currentScroll;
        this.showFullCartIcon = true;
      }
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    handleMenuFilter(filter) {
      this.menuFilterBy = filter;
    },
    checkChipHeight() {
      if (isMobile) {
        this.chipHeight = 56;
      } else {
        this.chipHeight = 64;
      }
    },
    showProduct(product) {
      this.$router.push({ name: "Product", params: { product: product } });
    },
    checkOut() {
      this.$router.push({ name: "Checkout" });
    },
  },
  filters: {
    toCurrency(value) {
      if (typeof value !== "number") return value;
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      return formatter.format(value);
    },
  },
};
</script>

<style scoped src="aos/dist/aos.css"></style>
<style scoped>
.menu-title {
  position: sticky;
  position: -webkit-sticky;
  top: 7.5rem;
}
@media only screen and (max-width: 600px) {
  .menu-title {
    top: 7rem;
  }
}
.menu-title-products {
  position: sticky;
  position: -webkit-sticky;
  top: 4.5rem;
}
@media only screen and (max-width: 600px) {
  .menu-title-products {
    top: 4rem;
  }
}
.toTopButton {
  margin-right: 7.7rem;
  opacity: 0.8;
}
@media only screen and (max-width: 600px) {
  .toTopButton {
    margin-right: 0;
  }
}

.text-wrapper {
  cursor: pointer;
}
.shopping-cart-container-large {
  position: sticky;
  position: -webkit-sticky;
  bottom: 1rem;
  opacity: 0.95;
  justify-content: center;
  transition: all 0.3s ease;
}
.fip-large {
  width: 9rem;
  height: 2.75rem;
  justify-content: space-around;
  transition: all 0.3s ease;
}
.shopping-cart-container {
  position: sticky;
  position: -webkit-sticky;
  bottom: 1rem;
  opacity: 0.8;
  justify-content: start;
  margin-left: -0.5rem;
  transition: all 0.3s ease;
}
.fip {
  width: 3rem;
  height: 2.75rem;
  justify-content: space-around;
  transition: all 0.3s ease;
}
</style>
